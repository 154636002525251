import { Box, Card, Grid, Typography } from '@mui/material';
import React from 'react'

const Services = () => {
  return (
    <Box>
      <Box py={3}>
        <Box px="15%" textAlign="left">
          <Box width="90%">
            <Box my={5}>
              <Typography variant="body1" color="secondary" fontWeight="bold">
                Technology
              </Typography>
            </Box>
            <Box my={3}>
              <Typography variant="h2" fontWeight={900} letterSpacing={1}>
                Scalable modern applications
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1">
                Building highly performant applications to fit all your needs. From a small business to an enterprise level solution.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box py={8} bgcolor="ghostwhite">
        <Box px="15%" textAlign="right">
          <Box my={3}>
            <Typography variant="h2" fontWeight={900}>
              Security first
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1">
              Be confident in data security provided by experts in modern security standards.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box py={8}>
        <Box px="15%" textAlign="left">
          <Box my={3}>
            <Typography variant="h2" fontWeight={900} letterSpacing={1}>
              End to end software development
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1">
              From start to finish, we provide the research, design, planning, and execution of the application development lifecyle. Our agile experts will guide you through each step of the process, to ensure we deliver products that meet your exact specifications.
            </Typography>
          </Box>
          <Grid container>
            <Grid item md={6}>
              <Box p={5}>
                <Card>
                  <Box p={3} height="12rem">
                    <Box my={2}>
                      <Typography variant="h6">
                        Research
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body1">
                        Our analysts will begin the development lifecycle by conducting user research
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box p={5}>
                <Card>
                  <Box p={3} height="12rem">
                    <Box my={2}>
                      <Typography variant="h6">
                        UI/UX Design
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body1">
                        Ensure your solutions meet the needs of your users and that they are aesthetically appealing and easy to use.
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box p={5}>
                <Card>
                  <Box p={3} height="12rem">
                    <Box my={2}>
                      <Typography variant="h6">
                        Software Development
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body1">
                        Build out the solution you need to improve your business functions and help your users or customers.
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box p={5}>
                <Card>
                  <Box p={3} height="12rem">
                    <Box my={2}>
                      <Typography variant="h6">
                        DevOps
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body1">
                        Support your applications with cost effective cloud solutions. Our experts in various cloud platforms will help you optimize all your hosting requirements.
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box p={5}>
                <Card>
                  <Box p={3} height="12rem">
                    <Box my={2}>
                      <Typography variant="h6">
                        Agile Coaching
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body1">
                        Improve the efficiency of your team's operations. Agile coaching can improve the performance of even the most functional teams.
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default Services;