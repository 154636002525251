import { Box, Button, List, ListItem, Typography } from '@mui/material'
import React from 'react'

const Careers = () => {
  return (
    <Box>
      <Box>
        <img width="100%" src={require('../images/programmer1.jpg')} />
      </Box>
      <Box>
        <Box my={5}>
          <Button color="primary" variant="contained">
            View open positions
          </Button>
        </Box>
        <Box px="10%">
          <Box textAlign={"left"}>
            <Typography variant="h5">
              Software Engineering
            </Typography>
          </Box>
          <Box textAlign={"left"}>
            <Typography variant="p">
              At Alpine IT, we're dedicated to hiring only the best software engineers. Our team is fully remote and distributed across the U.S. As an engineer, you'll be responsible for building our custom software solutions to one or many of our clients.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Careers