/* eslint-disable */
import React from 'react';
import { Switch, Redirect, Route } from 'react-router';
import Careers from './screens/Careers';
import Home from './screens/Home';
import Services from './screens/Services';

const Routes = ({ childProps }) => console.log(childProps.authStore) || (
  <Switch>
    <Route path="/home" exact component={Home} props={childProps} />
    <Route path="/services" exact component={Services} props={childProps} />
    <Route path="/careers" exact component={Careers} props={childProps} />
    <Route path="/industries" exact component={Home} props={childProps} />
    <Route path="/about-us" exact component={Home} props={childProps} />

    <Redirect to="/home" />
  </Switch>
);

export { Routes };
