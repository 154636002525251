import {
  Box, Divider, Link, Toolbar, Typography,
} from '@mui/material';
import React from 'react';

const Footer = () => (
  <Box style={{
    display: 'flex',
    flexDirection: 'row',
    position: 'fixed',
    bottom: 0,
    borderTop: '1px solid #8f91941c',
    width: '100%',
    background: 'white',
    zIndex: 999,
  }}
  >
    <Toolbar disableGutters>
      <Box
        px="15rem"
        width="100%"
      >
        <Link href="/" underline="none">
          <Typography variant="caption">© 2023 Alpine IT, LLC</Typography>
        </Link>
        {' · '}
        <Link href="/" underline="none">
          <Typography variant="caption">Privacy</Typography>
        </Link>
        {' · '}
        <Link href="/" underline="none">
          <Typography variant="caption">Terms</Typography>
        </Link>
        {' · '}
        <Link href="/" underline="none">
          <Typography variant="caption">Sitemap</Typography>
        </Link>
        {' · '}
        <Link href="/" underline="none">
          <Typography variant="caption">Contact Us</Typography>
        </Link>
      </Box>
    </Toolbar>
    <Divider />
  </Box>
);

export default Footer;
