import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import { List, ListItem, ListItemButton, ListItemText, Stack } from '@mui/material';
import { useHistory } from 'react-router';

const AlpineAppBar = () => {
  const history = useHistory();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Stack direction={"horizontal"}>
            <Box mr={4}>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/home"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  letterSpacing: '.1rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                Alpine<b>IT</b>
              </Typography>
            </Box>
            <Box mx={4}>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/services"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                Services
              </Typography>
            </Box>
            {/* <Box mx={4}>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/about-us"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                About Us
              </Typography>
            </Box> */}
            <Box mx={4}>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/careers"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                Careers
              </Typography>
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default AlpineAppBar