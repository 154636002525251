import { Box, Typography } from '@mui/material'
import React from 'react'

const Home = () => {
  return (
    <Box fullWidth bgcolor={"lightgrey"} height="25%">
      <Box>
        <Typography variant="h6">
          This site is currently under construction.
        </Typography>
        <Typography variant="body1">
          Alpine IT is growing quickly and is hiring! For any questions or inquiries, please email <a href="mailto:nik.weinmeister@build-alpine.com">nik.weinmeister@build-alpine.com</a>
        </Typography>
      </Box>
    </Box>
  )
}

export default Home;