import { Box, createTheme, ThemeProvider } from '@mui/material';
import { withRouter } from 'react-router-dom';
import './App.css';
import AlpineAppBar from './components/AlpineAppBar';
import Footer from './components/Footer';
import { Routes } from './Routes';

const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#3f72af",
      },
      secondary: {
        main: "#399f26"
      }
    },
    typography: {
      // In Japanese the characters are usually larger.
      fontSize: 16,
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Box className="App">
        <AlpineAppBar />
        <Routes childProps={{}} />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default withRouter(App);
